// App.tsx
import React, { useEffect, useState } from 'react';
import './App.css';

function App() {
  const [showNotification, setShowNotification] = useState(false);

  const setViewportScale = () => {
    const width = window.innerWidth;
    let scale = 1.0; // Default scale

    if (width > 768 && width < 1080) {
      scale = 0.65;
    } else if (width >= 1080) {
      scale = 1.0;
    }

    document.querySelector("meta[name=viewport]")?.setAttribute("content", `width=device-width, initial-scale=${scale}`);
  };

  useEffect(() => {
    setViewportScale(); // Set scale on initial load
    window.addEventListener('resize', setViewportScale); // Adjust scale on window resize

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', setViewportScale);
    };
  }, []);

  const copyToClipboard = (text: any) => {
    navigator.clipboard.writeText(text).then(() => {
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 2000); // Notification disappears after 2 seconds
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  }

  const socialMediaLinks = [
    { name: 'LinkedIn', url: 'https://linkedin.com/in/mhuzaifadev', icon: '/SocialMedia_Icons/LinkedIn.png' },
    { name: 'X.com', url: 'https://x.com/__mhuzaifa', icon: '/SocialMedia_Icons/X.com(Twitter).png' },
    { name: 'Instagram', url: 'https://instagram.com/mhuzaifadev', icon: '/SocialMedia_Icons/Instagram.png' },
    { name: 'GitHub', url: 'https://github.com/mhuzaifadev', icon: '/SocialMedia_Icons/GitHub.png' },
    { name: 'YouTube', url: 'https://youtube.com/@mhuzaifadev', icon: '/SocialMedia_Icons/YouTube.png' },
    { name: 'Medium', url: 'https://medium.com/@mhuzaifadev', icon: '/SocialMedia_Icons/Medium.png' },
    { name: 'GMAIL', url: 'mailto:mhuzaifadev@gmail.com', icon: '/SocialMedia_Icons/GMAIL.png' }
  ];

  const associations = [
    { name: 'Transpify', title: 'CTO & Co-founder', logo: '/Transpify.png', url: 'https://viralme.today' },
    { name: 'The Doers', title: 'Co-organizer', logo: '/TheDoers.png', url: 'https://www.youtube.com/@TheDoersShow' }
  ];

  return (
    <div className="App">
      <div className="content-wrapper">
        <div className="header">

          <span>Muhammad Huzaifa Shahbaz</span>
        </div>
        <img src="/HuzaifaImage.png" alt="Muhammad Huzaifa Shahbaz" className="profile-img" />
        <div className="details">
          <p className="username" onClick={() => copyToClipboard("@mhuzaifadev")}>@mhuzaifadev</p>
          {showNotification && <div className="notification">Copied to clipboard</div>}
          <div className="social-media-icons">
            {socialMediaLinks.map(link => (
              <a href={link.url} key={link.name} target="_blank" rel="noopener noreferrer">
                <img src={link.icon} alt={link.name} style={{ width: '30px', height: '30px' }} />
              </a>
            ))}
          </div>
          <div className="bio">
            <p>Innovator. Leader. Risk-taker 🚀 Building empires from a coffee shop ☕</p>
          </div>
          <div className="associations">
            <p>My Associations</p>
            <div className="associations-grid">
              {associations.map((assoc, index) => (
                <a href={assoc.url} key={index} target="_blank" rel="noopener noreferrer" className="association-card">
                  <img src={assoc.logo} alt={assoc.name} className="association-logo" />
                  <p className="association-title">{assoc.title}</p>
                </a>
              ))}
            </div>
            <div className="my-country">
              <img src="/Location.png" alt="Location Icon" className="location-icon" />
              <span>Pakistan</span>
              <img src="/Pakistan.png" alt="Flag of Pakistan" className="flag-icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
